import type { ViewPagerProps } from '@react-native-community/viewpager';
import { Children, forwardRef, useImperativeHandle, useRef } from 'react';
import { ScrollView, View } from 'react-native';

// NB this code was taken from:
// https://github.com/callstack/react-native-pager-view/issues/91#issuecomment-556110064
// It has not yet been tested on web since we don't use it anywhere, but we'll almost certainly
// have to update this "polyfill"
export const ViewPager = forwardRef<{}, ViewPagerProps>(function (props, ref) {
  const scrollViewRef = useRef<ScrollView>(null);
  const dataOrientation =
    props.orientation === 'vertical'
      ? {
          'data-snap-container-vertical': true,
        }
      : {
          'data-snap-container-horizontal': true,
        };
  useImperativeHandle(ref, () => {
    return {
      snapToItem: function () {
        // TODO
      },
    };
  });
  const elmts = Children.toArray(props.children);

  return (
    <ScrollView
      style={{}}
      contentContainerStyle={{ flex: 1 }}
      horizontal
      nestedScrollEnabled={true}
      {...dataOrientation}
      ref={scrollViewRef}
    >
      {elmts.map((elmt, index) => (
        <View key={index} style={{ width: '100%', height: '100%' }} data-snap-child={true}>
          {elmt}
        </View>
      ))}
    </ScrollView>
  );
});
